import React, { FC } from 'react'
import { Box, Paper, Tab, Tabs, Typography } from '@mui/material'
import { Code, Content, TabPanel, a11yProps, Surface, Markdown } from '@components'
import { TUseForm } from '@hooks/useForm'
import GenericPage, { GenericPageProps } from '@templates/Generic/Page'
import { graphql } from 'gatsby'

type SandflyViewProps = GenericPageProps & {
  form: TUseForm
  loading: boolean
  documentation: any
}

const SandflyViewTemplate: FC<SandflyViewProps> = ({
  form,
  loading = false,
  children,
  documentation,
  ...pageProps
}) => {
  const [currentTab, setCurrentTab] = React.useState(0)

  const handleTabChange = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue)
  }

  const handleValueChange = (value: string) => {
    form.setFieldValue('sandfly', value)
  }

  return (
    <GenericPage {...pageProps} metaTitle={`${pageProps.title} | Edit Sandfly`}>
      <Tabs
        value={currentTab}
        onChange={handleTabChange}
        aria-label="Custom Sandfly"
        indicatorColor="primary"
      >
        <Tab label={'Code'} {...a11yProps(0)} />
        <Tab label={'Documentation'} {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={currentTab} index={0}>
        <Surface>
          <Code
            loading={loading}
            height="100%"
            value={form?.values.sandfly || ''}
            copyButton={false}
            readOnly={false}
            onValueChange={handleValueChange}
          />{' '}
        </Surface>
      </TabPanel>

      <TabPanel value={currentTab} index={1}>
        <Paper>
          <Box px={4} py={3}>
            <Box>
              <Typography variant="overline">{`Documentation`}</Typography>
            </Box>
            <Box pb={2}>
              <Typography variant="h1">{`${documentation.frontmatter.title}`}</Typography>
            </Box>
            <Markdown children={documentation.rawMarkdownBody} />
          </Box>
        </Paper>
      </TabPanel>
    </GenericPage>
  )
}

export const query = graphql`
  fragment SandflyDocumentation on MarkdownRemark {
    id
    html
    rawMarkdownBody
    frontmatter {
      category
      slug
      tags
      title
    }
    wordCount {
      words
    }
    timeToRead
  }
`

export const initialValues = {
  sandfly: JSON.stringify(
    {
      active: true,
      custom: true,
      date_added: '2021-03-14T22:33:52Z',
      description:
        'Searches for generic shell commands that may be operating as a backdoor on the system (variant 1).',
      format: '3.0',
      max_cpu_load: 1,
      max_disk_load: 1,
      max_timeout: 360,
      severity: 3,
      name: 'process_backdoor_bindshell_generic_1_test',
      options: {
        engines: ['sandfly_engine_process'],
        explanation:
          "The process name '{process.name}' with PID '{process.pid}' may be operating as a generic shell backdoor based on the command line contents. Check this process and any open network ports to be sure it is not malicious as it could allow remote access or exfiltration of data.",
        process: {
          cmdline: [
            '.*>& \\/dev\\/tcp\\/.*0>&1',
            '.*>& \\/dev\\/udp\\/.*0>&1',
            'nohup.*exec.*<>.*\\/dev\\/tcp\\/.*',
            'nohup.*exec.*<>.*\\/dev\\/udp\\/.*'
          ],
          name: ['.*'],
          name_ignore: [],
          network_ports: {
            operating: true
          }
        },
        response: {
          process: {
            kill: false,
            suspend: false
          }
        }
      },
      os_compat: {
        linux: []
      },
      tags: [
        'attack.tactic.execution',
        'attack.id.T1059',
        'attack.id.T1100',
        'process'
      ],
      type: 'process',
      version: '2021-03-11T12:14:09'
    },
    null,
    2
  )
}

export default SandflyViewTemplate
