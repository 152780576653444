import React, { FC, useEffect } from 'react'
import SandflyEditTemplate from '@templates/Sandfly/Edit'
import { graphql, PageProps } from 'gatsby'
import useForm from '@hooks/useForm'
import { Cancel, Check, Refresh, Save } from '@mui/icons-material'
import { ButtonGroup } from '@mui/material'
import { Button } from 'gatsby-material-ui-components'
import useApi, { wasSuccess } from '@hooks/useApi'
import { getSandfly, SandflyResponse, updateSandfly } from '@utils/api'

const SandfliesEditPage: FC<PageProps<{ markdownRemark: any }>> = ({
  params,
  data
}) => {
  const api = useApi<SandflyResponse>({
    apiMethod: getSandfly,
    params: params.id
  })

  const updateApi = useApi({ apiMethod: updateSandfly, requestOnMount: false })
  const sandflyString = JSON.stringify(api.response, null, 2)
  const form = useForm({
    initialValues: { sandfly: '' }
  })

  const handleReset = () => {
    form.setFieldValue('sandfly', sandflyString)
  }

  const handleSave = async () => {
    let data

    // Parse Sandfly
    try {
      data = JSON.parse(form.values.sandfly)
    } catch (error) {
      updateApi.enqueueSnackbar('Invalid sandfly JSON.', {
        variant: 'error'
      })
      return
    }

    // Save Sandfly
    updateApi.makeRequest(data).then((res) => {
      if (wasSuccess(res)) {
        updateApi.enqueueSnackbar('Sandfly successfully updated.', {
          variant: 'success'
        })

        // Refresh Data
        api.makeRequest(params.id)
      }
    })
  }

  const hasChanged = form.values.sandfly !== sandflyString

  useEffect(() => {
    form.setFieldValue('sandfly', sandflyString)
  }, [api.response])

  return (
    <SandflyEditTemplate
      title={params.id}
      form={form as any}
      maxWidth="md"
      loading={false}
      documentation={data.markdownRemark}
      breadcrumbs={[
        {
          title: 'Sandflies',
          to: '/sandflies/'
        },
        {
          title: 'Edit Sandfly'
        }
      ]}
      actions={
        <ButtonGroup
          color="primary"
          variant="outlined"
          aria-label="Scan Page Actions"
        >
          <Button
            disabled={!hasChanged || api.loading}
            variant="contained"
            endIcon={!hasChanged ? <Check /> : <Save />}
            onClick={handleSave}
          >
            {!hasChanged ? 'Saved' : 'Save'}
          </Button>

          <Button
            disabled={!hasChanged}
            endIcon={<Refresh />}
            onClick={handleReset}
          >
            Reset
          </Button>
          <Button
            disabled={!hasChanged}
            endIcon={<Cancel />}
            onClick={() => window !== undefined && window.history.back()}
          >
            Cancel
          </Button>
        </ButtonGroup>
      }
    />
  )
}

export const query = graphql`
  {
    markdownRemark(
      frontmatter: { slug: { eq: "custom-sandflies-operation" } }
    ) {
      ...SandflyDocumentation
    }
  }
`

export default SandfliesEditPage
